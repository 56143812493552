#cursor {
    display: none;

    @include media-desktop {
        display: block;
        position: fixed;
        z-index: 999;
        left: 0;
        top: 0;
        pointer-events: none;
        will-change: transform;
    
        .cursor__circle {
            display: block;
            width: 300px;
            height: 400px;
            margin-top: -65%;
            margin-left: -50%;
            position: relative;
            overflow: hidden;
    
            .cursor__image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                pointer-events: none;
                z-index: 1;
                opacity: 0;
                transform-origin: top;
            }
        }
    }
}

#header {
    padding: 2rem 0;
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    background: transparent;
    transition: all .3s ease;

    #navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navbar {

            &-logo {
                position: relative;

                h1 {
                    @include font-body();
                }

                &-white {
                    opacity: 0;
                }

                &-img {
                    max-width: 260px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            &-menu {
                cursor: pointer;

                &-line {
                    height: 2px;
                    width: 30px;
                    background: $color-text;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#navbar-mobile {
    background: $color-graydark;
    color: $color-background;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    transform: scaleX(0);
    transform-origin: left;

    .navbar-mobile {

        &-list {
            margin-top: 7rem;
            list-style: none;

            &-item {
                display: block;
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    @include font-heading($size: 9vh);
                    @include stroke-hover($color-background);
                    text-transform: uppercase;
                }
            }
        }
    }
}

#loading {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: $color-background;
    color: $color-text;
    z-index: 99999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform-origin: right;
    @include font-small();

    .loading-logo {
        opacity: 0;

        &-img {
            max-width: 200px;
        }
    }

    .loading-text {
        position: fixed;
        bottom: 100px;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
}

#video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: none;
    opacity: 0;
    z-index: 9999;
    background: rgba($color: #000000, $alpha: 0.8);

    .video {

        &-close {
            position: absolute;
            top: 50px;
            right: 50px;
            cursor: pointer;
            z-index: 2;
            transition: all 0.3s ease;

            &:hover {
                transform: scale(0.9);
            }
        }

        &-frame {
            width: 100%;
            height: 100%;
            transform: scale(0.8);

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}

#footer {
    width: 100%;
    padding: 30px 0;
    margin-top: 2.5rem;

    .footer {


        &-nav {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    margin-right: 0.8rem;
                    display: inline-block;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}