@import '~rfs/scss';

$color-primary: #579b94;
$color-background: #FFFFFF;
$color-text: #000000;
$color-gray: #757575;
$color-graydark: #171717;

$font-size-root: 18px; // (also) font-size-body

// $font-size-big: 6vw; // (special) used in #intro section
$font-size-heading: 4rem;
$font-size-subheading: 2rem;
$font-size-body: 1rem;
$font-size-small: 0.6rem;

// Font weight
$font-weight-bold: 600;
$font-weight-heading: 600;
$font-weight-subheading: 600;
$font-weight-body: 400;
$font-weight-small: 400;

// Font letter spacing
$font-spacing-heading: -0.02em;
$font-spacing-subheading: 0;
$font-spacing-body: 0;
$font-spacing-small: 0.3em;

$margin-section: 10rem;
$margin-heading: 0.8em;

$space: 1em;

@mixin font-body($weight: $font-weight-body, $size: $font-size-body, $spacing: $font-spacing-body) {
    @include font-size($size);
    font-style: normal;
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1.4;
}

@mixin font-heading($weight: $font-weight-heading, $size: $font-size-heading, $spacing: $font-spacing-heading) {
    @include font-size($size);
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: normal;
}

@mixin font-subheading($weight: $font-weight-subheading, $size: $font-size-subheading, $spacing: $font-spacing-subheading) {
    @include font-size($size);
    font-weight: $weight;
    letter-spacing: $spacing;
    line-height: 1;
}

@mixin font-small($weight: $font-weight-small, $size: $font-size-small, $spacing: $font-spacing-small) {
    @include font-size($size);
    font-weight: $weight;
    letter-spacing: $spacing;
    text-transform: uppercase;
}

@mixin margin-horizontal($size: $space) {
    @include margin-left($size);
    @include margin-right($size);
}

@mixin margin-vertical($size: $space) {
    @include margin-top($size);
    @include margin-bottom($size);
}

@mixin padding-horizontal($size: $space) {
    @include padding-left($size);
    @include padding-right($size);
}

@mixin padding-vertical($size: $space) {
    @include padding-top($size);
    @include padding-bottom($size);
}

@mixin flex-center($direction: row) {
    display: flex;
    flex-direction: $direction;
    justify-content: center;
    align-items: center;
}

@mixin reset-space {
    margin: 0;
    padding: 0;
}

%border-heading {
    position: relative;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: -30px;
        width: 75px;
        border-top: 4px solid $color-text;
    }
}

// Other
$transition: all 0.2s ease-in-out;
$transition3s: all 0.3s ease;
// $borderradius: 0.3125rem;
$box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.1);
$box-shadow-hover: 0 1rem 2.5rem rgba(22, 28, 45, .1),
0 .5rem 1rem -.75rem rgba(22, 28, 45, .1);

%box-shadow {
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-3px) !important;
        box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, .1), 0 .5rem 1rem -.75rem rgba(22, 28, 45, .1) !important;
    }
}

%fillup {
    position: relative;
    overflow: hidden;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        background-color: $color-primary;
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        z-index: -1;
        transition: all 0.3s ease;
        height: 0;
    }

    &:hover {
        color: $color-background;

        &:before {
            height: 100%;
            z-index: -1;
        }
    }
}

@mixin stroke-hover($color: $color-text) {
    cursor: pointer;
    -webkit-text-stroke-color: $color;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-repeat: no-repeat;
    background-image: linear-gradient(135deg, $color 0%, $color 50%, transparent 50.1%);
    background-size: 0% 100%;
    transition: background-size .5s cubic-bezier(0.67, 0.01, 0.15, 0.98);

    &:hover {
        background-size: 220% 100%;
    }
}

// Responsive
@mixin media-desktop() {
    @media (min-width: 991.98px) {
        @content;
    }
}